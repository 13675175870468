import { ArrowLink } from "src/components/base/arrow-link";
import { ILinkCtaProps } from "src/types/cta";

export const LinkCta = ({ url, name, target, colorScheme, ...props }: ILinkCtaProps) => {
    return (
        <ArrowLink href={url} target={target} colorScheme={colorScheme ?? "green"} {...props}>
            {name}
        </ArrowLink>
    );
};
