import { Text, AvatarGroup, useBreakpointValue } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";
import { colorSchemeMap } from "src/components/modules/underlying-lists-module/underlying-list-switch-button/color-scheme-map";
import { IUnderlyingListItem } from "src/components/modules/underlying-lists-module/underlying-lists-module.types";
import { EnumUnderlyingListColorScheme } from "src/components/pages/underlying-overview/constants";
import { UnderlyingLogo } from "src/components/pages/underlying-overview/underlying-logo";

interface IUnderlyingLogosButton extends ButtonProps {
    label: string;
    colorScheme: EnumUnderlyingListColorScheme;
    listItems?: IUnderlyingListItem[];
}

const REQUIRED_NUMBER_OF_LOGOS = 3;
const UnderlyingListSwitchButton = ({ label, colorScheme, listItems, ...props }: IUnderlyingLogosButton) => {
    const isMobileView = useBreakpointValue([true, false]);
    const logoSize = isMobileView ? "sm" : "md";

    const getFilledUnderlyingLogoIsinsFromListItems = (listItems?: IUnderlyingListItem[]) => {
        const dummyLogoIsins = ["1", "2", "3"];
        if (listItems) {
            if (listItems.length >= REQUIRED_NUMBER_OF_LOGOS) {
                return listItems.slice(0, REQUIRED_NUMBER_OF_LOGOS).map(item => item.isin);
            }

            const filledListItems = [...listItems.map(item => item.isin)];
            for (let i = 0; i < REQUIRED_NUMBER_OF_LOGOS - filledListItems.length; i++) {
                filledListItems.push(dummyLogoIsins[i]);

                return filledListItems;
            }
        }

        return dummyLogoIsins;
    };

    return (
        <Button
            sx={{ border: "2px solid currentColor", ...colorSchemeMap[colorScheme] }}
            variant="ghost"
            height="auto"
            display="flex"
            flexWrap="wrap"
            justifyContent={["center", "space-between"]}
            px={2}
            pt={[1, 2]}
            pb={2}
            {...props}
        >
            <Text fontSize={["sm", "lg"]} fontWeight="semibold">
                {label}
            </Text>
            <AvatarGroup size={logoSize} spacing={[-2, -1]} width={["100%", "auto", "auto"]} justifyContent={["center"]} mt={[1, 0]}>
                {getFilledUnderlyingLogoIsinsFromListItems(listItems).map(isin => (
                    <UnderlyingLogo key={isin} isin={isin} />
                ))}
            </AvatarGroup>
        </Button>
    );
};

export { UnderlyingListSwitchButton };
