import { Box, Grid, Divider } from "@chakra-ui/react";
import { ScrollSnap, ScrollSnapItem } from "src/components/common/scroll-snap";
import { ITeaserArticle } from "src/types/common";
import { TeaserArticle, EnumImageSizeVariant } from "./teaser-article";

const ONE_TEASER = 1;
const TWO_TEASERS = 2;
const THREE_TEASERS = 3;
const MAX_TEASERS = 4;

export const TeaserArticles = ({ items }: { items: ITeaserArticle[] }) => {
    switch (items.length) {
        case ONE_TEASER:
            return (
                <TeaserArticle
                    imageSizeVariant={EnumImageSizeVariant.Big}
                    articleData={items[0]}
                    display="grid"
                    bodyBoxProps={{ py: [0, 0, 0, 4], justifyContent: "center" }}
                    readMoreBoxProps={{ pt: [2, 3] }}
                    gridTemplateColumns={["initial", "1fr 1fr"]}
                    gridGap={[2, 4]}
                />
            );

        case TWO_TEASERS:
            return (
                <Grid gridTemplateColumns={["initial", "1fr 1fr"]} gridGap={[5, 4]}>
                    {items.map((articleData, index) => (
                        <TeaserArticle
                            key={`${index}${articleData.link}`}
                            imageSizeVariant={EnumImageSizeVariant.Big}
                            bodyBoxProps={{ pt: 2 }}
                            articleData={articleData}
                        />
                    ))}
                </Grid>
            );

        case THREE_TEASERS:
            return (
                <ScrollSnap gap={[0, 2, 4]} overflowX={["auto", "visible"]} templateColumns={["repeat(3, auto)", "repeat(3, minmax(0, 1fr))"]}>
                    {items.map((articleData, index) => (
                        <ScrollSnapItem key={`${index}${articleData.link}`} pr={[2, 0]}>
                            <TeaserArticle width={["288px", "auto"]} bodyBoxProps={{ pt: 2 }} articleData={articleData} />
                        </ScrollSnapItem>
                    ))}
                </ScrollSnap>
            );

        case MAX_TEASERS:
            return (
                <Grid gridTemplateColumns={["initial", "1fr 1fr", "1fr minmax(0, 1fr)"]} gridGap={[3, 5, 4]}>
                    <TeaserArticle key={1435} imageSizeVariant={EnumImageSizeVariant.Big} bodyBoxProps={{ py: 2, pb: 0 }} articleData={items[0]} />
                    <Divider borderColor="gray.100" display={["block", "none"]} />
                    <Box>
                        {items.slice(1, MAX_TEASERS).map((articleData, index) => (
                            <Box key={`${index}${articleData.link}`}>
                                <TeaserArticle
                                    bodyBoxProps={{ pt: index === 0 ? 0 : [2, 2, 3], pb: [2, 2, 3] }}
                                    readMoreBoxProps={{ pt: 2 }}
                                    hideImage
                                    hideDescription
                                    articleData={articleData}
                                    gridTemplateColumns={["initial", "minmax(0, 1fr)"]}
                                />
                                {index !== 2 && <Divider borderColor="gray.100" />}
                            </Box>
                        ))}
                    </Box>
                </Grid>
            );

        default:
            return null;
    }
};
