export const colorSchemeMap = {
    teal: {
        bg: "white",
        color: "gray.800",
        borderColor: "teal.600",
        _hover: {
            color: "white",
            bg: "teal.600",
        },
        _active: {
            color: "white",
            bg: "teal.600",
        },
        _focus: {
            bg: "teal.600",
        },
        _disabled: {
            bg: "white",
            color: "gray.800",
        },
    },
    green: {
        bg: "white",
        color: "gray.800",
        borderColor: "green.600",
        _hover: {
            color: "white",
            bg: "green.600",
        },
        _active: {
            color: "white",
            bg: "green.600",
        },
        _focus: {
            bg: "green.600",
        },
        _disabled: {
            bg: "white",
            color: "gray.800",
        },
    },
    sky: {
        bg: "white",
        color: "gray.800",
        borderColor: "sky.600",
        _hover: {
            color: "white",
            bg: "sky.600",
        },
        _active: {
            color: "white",
            bg: "sky.600",
        },
        _focus: {
            bg: "sky.600",
        },
        _disabled: {
            bg: "white",
            color: "gray.800",
        },
    },
    blue: {
        bg: "white",
        color: "gray.800",
        borderColor: "blue.600",
        _hover: {
            color: "white",
            bg: "blue.600",
        },
        _active: {
            color: "white",
            bg: "blue.600",
        },
        _focus: {
            bg: "blue.600",
        },
        _disabled: {
            bg: "white",
            color: "gray.800",
        },
    },
};
