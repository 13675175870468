import { AspectRatio, BoxProps, Text, Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Article } from "src/components/base";
import { ArrowLink } from "src/components/base/arrow-link";
import { ISrc, ResponsiveImage, IResponsiveImageProps } from "src/components/base/responsive-image";
import { WfLink, WfLinkGhost } from "src/components/base/wf-link";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { WfAvatar } from "src/components/common/wf-avatar";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { ITeaserArticle } from "src/types/common";
import { IMAGE_VARIATION_SIZES } from "./constants";

const TEASER_ARTICLE_IMAGE_LINK_TEST_ID = "teaser-article-image-link";
const TEASER_ARTICLE_AUTHOR_LINK_TEST_ID = "teaser-article-author-link";
const TEASER_ARTICLE_READ_MORE_LINK_TEST_ID = "teaser-article-read-more-link";
const TEASER_ARTICLE_TITLE_LINK_TEST_ID = "teaser-article-title-link";

export enum EnumImageSizeVariant {
    Medium = "medium",
    Big = "big",
}

export interface ITeaserArticleProps extends BoxProps {
    articleData: ITeaserArticle;
    imageSizeVariant?: EnumImageSizeVariant;
    bodyBoxProps?: BoxProps;
    imageStyleProps?: Omit<IResponsiveImageProps, "alt" | "baseUrl">;
    readMoreBoxProps?: BoxProps;
    hideImage?: boolean;
    hideDescription?: boolean;
}

export const TeaserArticle = ({
    articleData: { image, link, releaseDate, title, author, description },
    imageSizeVariant = EnumImageSizeVariant.Medium,
    hideImage = false,
    hideDescription = false,
    bodyBoxProps,
    imageStyleProps,
    readMoreBoxProps,
    ...boxProps
}: ITeaserArticleProps) => {
    const { t } = useTranslation("common");
    const { formatDateShort } = useDateTimeFormatter();
    const srcSet = imageSizeVariant === EnumImageSizeVariant.Big ? IMAGE_VARIATION_SIZES.big.srcSet : IMAGE_VARIATION_SIZES.medium.srcSet;

    return (
        <Article w="auto" {...boxProps}>
            {image && !hideImage && (
                <WfLink display="flex" href={link.url} target={link.target} data-test-id={TEASER_ARTICLE_IMAGE_LINK_TEST_ID}>
                    <AspectRatio w="100%" ratio={3 / 2}>
                        <ResponsiveImage
                            height="auto"
                            width="100%"
                            borderRadius="4px"
                            srcSet={srcSet as ISrc[]}
                            baseUrl={image.url}
                            alt={image.alt}
                            {...imageStyleProps}
                        />
                    </AspectRatio>
                </WfLink>
            )}

            <Flex flexFlow="column" {...bodyBoxProps}>
                {releaseDate && (
                    <Text as="time" fontSize="sm" color="gray.400" mb={0.5}>
                        {formatDateShort(releaseDate)}
                    </Text>
                )}

                {title && (
                    <Text as="h3" fontSize={["lg", "xl"]} lineHeight={["base", "tall"]} fontWeight="bold" mb={[1, 2, 1]} noOfLines={3}>
                        <WfLinkGhost display="block" href={link.url} target={link.target} data-test-id={TEASER_ARTICLE_TITLE_LINK_TEST_ID}>
                            {title}
                        </WfLinkGhost>
                    </Text>
                )}

                {author && (
                    <Flex mb={[2, 1]} alignItems="center">
                        <WfAvatar
                            as={WfLinkUnstyled}
                            href={author.authorUrl}
                            size="xs"
                            flexShrink={0}
                            src={author.image.url}
                            alt={author.image.alt}
                            mr={1}
                        />

                        <WfLinkGhost
                            fontSize="sm"
                            href={author.authorUrl}
                            fontWeight="semibold"
                            noOfLines={1}
                            wordBreak="break-all"
                            data-test-id={TEASER_ARTICLE_AUTHOR_LINK_TEST_ID}
                        >
                            {author.name}
                        </WfLinkGhost>
                    </Flex>
                )}

                {!hideDescription && <SimpleRichText mb={2} noOfLines={[3, 4, 3]} fontSize="md" lineHeight="base" text={description} />}

                <Box {...readMoreBoxProps}>
                    <ArrowLink href={link.url} target={link.target} data-test-id={TEASER_ARTICLE_READ_MORE_LINK_TEST_ID}>
                        {t("read-more")}
                    </ArrowLink>
                </Box>
            </Flex>
        </Article>
    );
};
