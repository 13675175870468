import { Box } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { ILink } from "src/types/common";
import { ITeaserArticle } from "src/types/common";
import { EnumHorizontalAlignment } from "src/types/common-enum";
import { TeaserArticles } from "./components/teaser-articles";

const BLOG_TEASER_MODULE_HEADING_TEST_ID = "blog-teaser-module-heading";

export interface IBlogTeaserModuleProps {
    titleAsH1?: boolean;
    title?: string;
    headerAlignment: EnumHorizontalAlignment;
    items: ITeaserArticle[];
    link?: ILink;
}

export const BlogTeaserModule = ({ title, headerAlignment, items, titleAsH1, link }: IBlogTeaserModuleProps) => {
    const textAlign = headerAlignment === EnumHorizontalAlignment.Left ? "left" : "center";

    return (
        <Container px={[3, 5, 3]} pos="relative">
            {title && (
                <ModulePrimaryHeadline
                    titleAsH1={titleAsH1}
                    mb={[4, 5, 6, 7]}
                    textAlign={textAlign}
                    data-test-id={BLOG_TEASER_MODULE_HEADING_TEST_ID}
                >
                    {title}
                </ModulePrimaryHeadline>
            )}

            <Box maxW="100%" overflow={["hidden", "visible"]} pos="relative">
                <TeaserArticles items={items} />
                {link && (
                    <Box display="flex" justifyContent="end" mt={5}>
                        <ArrowLink href={link.url} target={link.target} colorScheme="green">
                            {link.name}
                        </ArrowLink>
                    </Box>
                )}
            </Box>
        </Container>
    );
};
