import { AspectRatio, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalProps } from "@chakra-ui/react";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";

interface IVideoModalProps extends Omit<ModalProps, "children"> {
    title: string;
    youtubeLink: string;
}

export const VideoModal = ({ title, youtubeLink, ...modalProps }: IVideoModalProps) => (
    <Modal {...modalProps}>
        <ModalOverlay />
        <ModalContent maxW={["full", "750px", "750px", "708px"]}>
            <ModalHeader>{title}</ModalHeader>
            <WfModalCloseButton />
            <ModalBody mb={5} px={0}>
                <AspectRatio ratio={16 / 9} bg="gray.800">
                    <iframe frameBorder="0" allowFullScreen allow="autoplay; encrypted-media" src={youtubeLink} />
                </AspectRatio>
            </ModalBody>
        </ModalContent>
    </Modal>
);
