import { useMemo } from "react";
import { Flex, Text, Grid, Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfAccordion } from "src/components/base/wf-accordion";
import { WfHeading } from "src/components/base/wf-heading";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { UnderlyingListItemDetails } from "src/components/modules/underlying-lists-module/list/underlying-list-item-details";
import { UnderlyingListItemHeading } from "src/components/modules/underlying-lists-module/list/underlying-list-item-heading";
import { DEFAULT_DAYS_COUNT } from "src/components/modules/underlying-lists-module/underlying-list-module";
import { IUnderlyingListItem } from "src/components/modules/underlying-lists-module/underlying-lists-module.types";
import { EnumUnderlyingListColorScheme } from "src/components/pages/underlying-overview/constants";

interface IUnderlyingListProps {
    title: string;
    description: string;
    colorScheme: EnumUnderlyingListColorScheme;
    daysCount: number;
    items?: IUnderlyingListItem[];
    onChange?: (index: number) => void;
    expandedItemIndex?: number;
    isVisible?: boolean;
    dataTestId?: string;
    headingTestId?: string;
    descriptionTestId?: string;
    showOverlay?: boolean;
}

const MAX_LIST_ITEMS = 5;
const UnderlyingList = ({
    title,
    description,
    items,
    colorScheme,
    daysCount,
    onChange,
    expandedItemIndex,
    isVisible = true,
    dataTestId,
    headingTestId,
    descriptionTestId,
    showOverlay,
}: IUnderlyingListProps) => {
    const { t } = useTranslation(["date-range", "common"]);
    const accordionItems = useMemo(() => {
        return items
            ? items.slice(0, MAX_LIST_ITEMS).map(item => ({
                  label: <UnderlyingListItemHeading isin={item.isin} title={item.name} performance={item.priceChange} />,
                  value: (
                      <UnderlyingListItemDetails
                          isin={item.isin}
                          price={item.bid}
                          popularityChange={item.popularityChange}
                          underlyingDetailsUrl={item.underlyingDetailsUrl}
                          daysCount={daysCount}
                          canBeFollowed={item.canBeFollowed}
                      />
                  ),
              }))
            : [];
    }, [daysCount, items]);

    return (
        <Grid display={isVisible ? undefined : "none"} alignContent="start" data-test-id={dataTestId}>
            <WfHeading display={["none", "none", "block"]} order={-1} as={"h2"} fontSize={"2xl"} mb={3} data-test-id={headingTestId}>
                {title}
            </WfHeading>
            <Flex justifyContent="space-between" p={2} borderTop="2px" borderBottom="2px" borderColor={`${colorScheme}.600`} fontSize={["sm", "md"]}>
                <Text fontWeight="semibold">{t("stock", { ns: "common" })}</Text>
                <Text fontWeight="semibold">{`${t("performance", { ns: "common" })} ${t(
                    daysCount === DEFAULT_DAYS_COUNT ? "range-7-d-long" : "range-30-d-long"
                )}`}</Text>
            </Flex>
            <Box position="relative" minHeight={showOverlay ? ["270px", "325px", 0] : undefined}>
                <WfAccordion variant="compact" data={accordionItems} onChange={!showOverlay ? onChange : undefined} index={expandedItemIndex} />
                {showOverlay && <Box backdropFilter="blur(8px)" w="100%" h="calc(100% - 65px)" position="absolute" bottom="0" left="0"></Box>}
            </Box>
            <SimpleRichText
                fontSize={["sm", "sm", "md"]}
                order={[-1, -1, 0]}
                mt={[4, 4, 3]}
                mb={[3, 3, 0]}
                text={description}
                data-test-id={descriptionTestId}
            />
        </Grid>
    );
};

export { UnderlyingList, EnumUnderlyingListColorScheme };
