import { Flex, HStack, VStack, Box } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfHeading } from "src/components/base/wf-heading";
import { IVotingDetailData } from "src/components/common/voting/types";
import { useVotingStatus } from "src/components/common/voting/use-voting-status";
import { VotingAnswersBlock } from "src/components/common/voting/voting-answers-block";
import { VotingNavigationButtons } from "src/components/common/voting/voting-navigation-buttons";
import { VotingRemainingDays } from "src/components/common/voting/voting-remaining-days";
import { VotingTopTraders } from "src/components/common/voting/voting-top-traders";

const VOTING_MODULE_QUESTION_TEST_ID = "voting-module-question";

export interface IVotingDetailProps extends IVotingDetailData {
    canGoBack: boolean;
    canGoForward: boolean;
    onVoteChange: (userChoice: number) => void;
    onPreviousButtonClick: () => void;
    onNextButtonClick: () => void;
}

export const VotingDetail = ({
    votingUntilDate,
    currentDate,
    question,
    answers,
    image,
    link,
    result,
    canGoBack,
    canGoForward,
    onVoteChange,
    onPreviousButtonClick,
    onNextButtonClick,
}: IVotingDetailProps) => {
    const { hasEnded } = useVotingStatus(votingUntilDate, currentDate);

    return (
        <>
            <HStack display={["none", "none", "block"]} position={[null, null, "absolute"]} right={3} top={2}>
                <VotingRemainingDays votingUntilDate={votingUntilDate} currentDate={currentDate} />
            </HStack>

            <HStack spacing={4}>
                <VStack align="left" spacing={[1, 2]} minW={["100%", "100%", "60%"]}>
                    <WfHeading as="h3" fontSize={["md", "xl"]} pb={[2, 0, 1]} data-test-id={VOTING_MODULE_QUESTION_TEST_ID}>
                        {question}
                    </WfHeading>

                    <HStack display={["block", "block", "none"]} pb={[1]}>
                        <VotingRemainingDays votingUntilDate={votingUntilDate} currentDate={currentDate} />
                    </HStack>

                    <VotingAnswersBlock
                        answers={answers}
                        selectedAnswerIndex={result?.userChoice?.[0] ?? null}
                        voteSummary={result?.voteSummary ?? null}
                        hasVotingEnded={hasEnded}
                        onVoteChange={onVoteChange}
                    />
                    <VotingNavigationButtons
                        canGoBack={canGoBack}
                        canGoForward={canGoForward}
                        onPreviousButtonClick={onPreviousButtonClick}
                        onNextButtonClick={onNextButtonClick}
                    />
                </VStack>
                {image && (
                    <Box w="100%" alignSelf="stretch" display={["none", "none", "block"]} position="relative">
                        <ResponsiveImage
                            position="absolute"
                            w="100%"
                            h="100%"
                            objectFit="cover"
                            baseUrl={image.url}
                            alt={image.alt}
                            srcSet={[{ queryParams: { w: 420 } }]}
                        />
                    </Box>
                )}
            </HStack>
            <VotingTopTraders
                topTradersHaveVotedLikeUser={result?.topTradersHaveVotedLikeUser ?? null}
                topTraderWikifolios={result?.topTraderWikifolios ?? null}
            />
            {link && (
                <Flex direction={["row", "row", "row-reverse"]}>
                    <ArrowLink display="inline-block" mt={[2, 3, 4]} href={link.url} target={link.target} colorScheme="green">
                        {link.name}
                    </ArrowLink>
                </Flex>
            )}
        </>
    );
};
