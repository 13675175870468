import { ReactElement, useEffect, useRef } from "react";
import {
    // eslint-disable-next-line no-restricted-imports
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    AccordionProps,
} from "@chakra-ui/react";
import { IconCaret } from "src/components/icons";
import { isServerSide } from "src/utils/common-util";
import { SimpleRichText } from "../common/rich-text/simple-rich-text";

export interface IWfAccordionItem {
    label: string | ReactElement;
    value: string | ReactElement;
    anchor?: string;
}

export interface IWfAccordionProps extends AccordionProps {
    data: IWfAccordionItem[];
    shouldScrollIntoView?: boolean;
}

interface IAccordionItemWrapperProps {
    item: IWfAccordionItem;
    shouldScrollIntoView: boolean;
}

const AccordionItemWrapper = ({ item, shouldScrollIntoView }: IAccordionItemWrapperProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const scrollIntoView = () => {
        setTimeout(() => {
            const rect = ref.current?.getBoundingClientRect();
            const SCROLL_TOP_MARGIN = 70;
            const outOfViewport = rect && (rect.top + rect.height > window.innerHeight - SCROLL_TOP_MARGIN || rect.top < SCROLL_TOP_MARGIN);
            if (outOfViewport) {
                ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 100);
    };

    useEffect(() => {
        if (isServerSide() || !item.anchor || !window.location.hash) {
            return;
        }

        if (item.anchor === window.location.hash.substr(1)) {
            setTimeout(() => ref.current?.querySelector("button")?.click(), 100);
        }
    }, [item.anchor]);

    return (
        <AccordionItem ref={ref}>
            {({ isExpanded }) => (
                <>
                    <AccordionButton onClick={() => !isExpanded && shouldScrollIntoView && scrollIntoView()}>
                        {item.label}
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <AccordionIcon {...({ as: IconCaret } as any)} />
                    </AccordionButton>
                    <AccordionPanel>{typeof item.value === "string" ? <SimpleRichText text={item.value} /> : item.value}</AccordionPanel>
                </>
            )}
        </AccordionItem>
    );
};

export const WfAccordion = ({ data, shouldScrollIntoView = false, ...accordionProps }: IWfAccordionProps) => (
    <Accordion size="lg" allowToggle={!accordionProps.allowMultiple} {...accordionProps}>
        {data.map((item, index) => (
            <AccordionItemWrapper key={index} item={item} shouldScrollIntoView={shouldScrollIntoView} />
        ))}
    </Accordion>
);
