import { useState } from "react";
import { useVoteUser } from "src/api/client/vote-user.api";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { IVotingDetailData } from "src/components/common/voting/types";
import { Container } from "src/components/layout/container";
import { VotingDetail } from "src/components/modules/voting-module/voting-detail";
import { getGlobals } from "src/consts/globals";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { authModalStore } from "src/stores/auth-modal-store";
import { useUserStore } from "src/stores/user-store";
import { getObjectCopy } from "src/utils/common-util";

export interface IVotingModuleProps {
    title: string;
    votes: ReadonlyArray<IVotingDetailData>;
}

export const VotingModule = ({ title, votes: initialVotes }: IVotingModuleProps) => {
    const { isLoggedIn } = useUserStore();
    const { openLoginModal } = useLinkBuilder();
    const [votes, setVotes] = useState(initialVotes);

    const voteUseMutation = useVoteUser();
    const { currentPageNodeId } = getGlobals();

    const onVoteChange = (questionIndex: number, userChoice: number) => {
        if (!isLoggedIn) {
            authModalStore.returnUrl = document.location.pathname;
            openLoginModal();
            return;
        }

        const vote = votes[questionIndex];

        voteUseMutation(
            { voteTemplateId: vote.voteTemplateId, value: userChoice, placement: currentPageNodeId },
            {
                onSuccess: data => {
                    if (!data) {
                        return;
                    }

                    setVotes(prevState => {
                        const clonedVotes = getObjectCopy(prevState);
                        clonedVotes[questionIndex].result = data;
                        return clonedVotes;
                    });
                },
            }
        );
    };

    const onPreviousButtonClick = () => {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const onNextButtonClick = () => {
        if (selectedIndex < votes.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        }
    };

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const isFirst = selectedIndex === 0;
    const isLast = selectedIndex === votes.length - 1;

    return (
        <Container pos="relative" overflow="hidden">
            <ModulePrimaryHeadline mb={4}>{title}</ModulePrimaryHeadline>
            <VotingDetail
                key={selectedIndex}
                {...votes[selectedIndex]}
                canGoBack={!isFirst}
                canGoForward={!isLast}
                onVoteChange={userChoice => onVoteChange(selectedIndex, userChoice)}
                onPreviousButtonClick={onPreviousButtonClick}
                onNextButtonClick={onNextButtonClick}
            />
        </Container>
    );
};
