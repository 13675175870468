import { AspectRatio, Box, BoxProps, Stack, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FullSizeFlexCentered } from "src/components/base";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { MultiCta } from "src/components/common/cta/cta";
import { ModuleSecondaryHeadline, PrimaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconPlay } from "src/components/icons";
import { Container, ContainerBig } from "src/components/layout/container";
import { VideoModal } from "src/components/modals/video-modal/video-modal";
import { IImageVideoModuleProps } from "src/components/modules/image-video-module/image-video-module.types";
import { EnumHorizontalAlignment } from "src/types/common-enum";

const GrayBackground = ({ align }: { align: EnumHorizontalAlignment }) => {
    const props: BoxProps = {
        display: ["none", "block"],
        pos: "absolute",
        top: 0,
        [align === EnumHorizontalAlignment.Left ? "right" : "left"]: ["0", "500px", "650px", "823px"],
        boxSize: "100%",
        bg: "gray.50",
        zIndex: -1,
    };

    return <Box {...props} />;
};

export const ImageVideoModule = ({
    headline,
    headlineAsH1,
    title,
    titleAsH2,
    description,
    image,
    imageAlign,
    video,
    showGrayBg,
    isFirstModule,
    ...ctaProps
}: IImageVideoModuleProps) => {
    const { t } = useTranslation("common");
    const videoModal = useDisclosure();

    return (
        <>
            {headline && (
                <Container>
                    <PrimaryHeadline mb={[4, 5]} titleAsH1={headlineAsH1}>
                        {headline}
                    </PrimaryHeadline>
                </Container>
            )}
            <ContainerBig pos="relative" overflow="hidden">
                <Container pos="relative" py={showGrayBg ? [0, 7, 10] : 0}>
                    {showGrayBg && <GrayBackground align={imageAlign} />}
                    <Stack
                        direction={["column", imageAlign === EnumHorizontalAlignment.Left ? "row" : "row-reverse"]}
                        spacing={[3, 3, 4, 6]}
                        alignItems="flex-start"
                    >
                        <AspectRatio
                            position="relative"
                            flexShrink={0}
                            w={["100%", 319, 400, 554]}
                            ratio={3 / 2}
                            role={video ? "button" : undefined}
                            aria-label={video ? t("click-to-play-video") : undefined}
                        >
                            <Box>
                                <ResponsiveImage
                                    srcSet={[
                                        { queryParams: { w: 312, h: 208, fit: "crop" } },
                                        { queryParams: { w: 319, h: 213, fit: "crop" } },
                                        { queryParams: { w: 400, h: 267, fit: "crop" } },
                                        { queryParams: { w: 554, h: 369, fit: "crop" } },
                                    ]}
                                    baseUrl={image.url}
                                    alt={image.alt}
                                    rounded="4px"
                                    w="100%"
                                    loading={isFirstModule ? "eager" : "lazy"}
                                />
                                {video && (
                                    <>
                                        <FullSizeFlexCentered onClick={videoModal.onOpen} _hover={{ bg: "whiteAlpha.300" }}>
                                            <IconPlay boxSize={10} color="white" border="4px" />
                                        </FullSizeFlexCentered>
                                        <VideoModal title={video.title} youtubeLink={video.url} {...videoModal} />
                                    </>
                                )}
                            </Box>
                        </AspectRatio>
                        <Box width="100%" overflow="hidden" flexGrow={1}>
                            {title && <ModuleSecondaryHeadline titleAsH2={titleAsH2}>{title}</ModuleSecondaryHeadline>}
                            <SimpleRichText mb={3} fontSize={["md", "md", "lg"]} lineHeight={["base", "base", "tall"]} text={description} />
                            <MultiCta {...ctaProps} />
                        </Box>
                    </Stack>
                </Container>
            </ContainerBig>
        </>
    );
};
