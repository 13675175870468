import { Box, BoxProps } from "@chakra-ui/react";
import { IFractionDigits } from "src/hooks/formatters/number-formatter";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { getPerformanceColor } from "src/utils/color-util";
import { isNullOrUndefined } from "src/utils/common-util";

interface IPerformanceValueProps extends BoxProps {
    value: number | null;
    fractionDigits?: IFractionDigits;
}

export const PerformanceValue = ({ value, fractionDigits = 1, ...boxProps }: IPerformanceValueProps) => {
    const { formatPerformance } = useNumberFormatter();

    let valueDisplay = "-";
    let color: "green.600" | "red.600" | undefined;

    if (!isNullOrUndefined(value)) {
        valueDisplay = formatPerformance(value, fractionDigits);
        color = getPerformanceColor(valueDisplay);
    }

    return (
        <Box color={color} {...boxProps}>
            {valueDisplay}
        </Box>
    );
};
