export const IMAGE_VARIATION_SIZES = {
    big: {
        srcSet: [
            { queryParams: { w: 312, h: 208, fit: "crop" } },
            { queryParams: { w: 323, h: 215, fit: "crop" } },
            { queryParams: { w: 445, h: 297, fit: "crop" } },
            { queryParams: { w: 545, h: 364, fit: "crop" } },
        ],
    },

    medium: {
        srcSet: [
            { queryParams: { w: 288, h: 192, fit: "crop" } },
            { queryParams: { w: 213, h: 142, fit: "crop" } },
            { queryParams: { w: 286, h: 190, fit: "crop" } },
            { queryParams: { w: 352, h: 235, fit: "crop" } },
        ],
    },
};
