import { useEffect } from "react";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { isServerSide } from "src/utils/common-util";

interface IUseContentPageVisitTrackingProps {
    activityType?: EnumActivityType;
    includeHash?: boolean;
}
export const useContentPageVisitTracking = ({
    activityType = EnumActivityType.ContentPageVisit,
    includeHash = false,
}: IUseContentPageVisitTrackingProps) => {
    const genericActivity = useGenericActivityFromElysiumMutation();

    useEffect(() => {
        const parameter = document.location.pathname + document.location.search;
        const hash = document.location.hash;

        if (!isServerSide()) {
            genericActivity({
                activityTypeId: activityType,
                parameter: includeHash && hash ? `${parameter}${hash}` : parameter,
                referrer: document.referrer || null,
            });
        }
    }, [activityType, genericActivity, includeHash]);
};
