import { IUnderlyingListModuleProps } from "src/components/modules/underlying-lists-module/underlying-list-module";
import { IUnderlyingListItem, IUnderlyingLists } from "src/components/modules/underlying-lists-module/underlying-lists-module.types";

export const getAllItemsFromUnderlyingListModule = (lists: IUnderlyingListModuleProps["underlyingLists"]): IUnderlyingListItem[] => {
    if (!lists) {
        return [];
    }

    const { underlyingListForSevenDays, underlyingListForThirtyDays } = lists;
    const combinedUnderlyingListItems: IUnderlyingListItem[] = [];

    if (underlyingListForSevenDays) {
        combinedUnderlyingListItems.push(...getAllItemsFromUnderlyingLists(underlyingListForSevenDays));
    }

    if (underlyingListForThirtyDays) {
        combinedUnderlyingListItems.push(...getAllItemsFromUnderlyingLists(underlyingListForThirtyDays));
    }

    return combinedUnderlyingListItems;
};

const getAllItemsFromUnderlyingLists = (underlyingList: IUnderlyingLists) => {
    const underlyingListItems: IUnderlyingListItem[] = [];
    Object.entries(underlyingList).forEach(([, list]) =>
        list.forEach((item: IUnderlyingListItem) => {
            if (!underlyingListItems.includes(item)) {
                underlyingListItems.push(item);
            }
        })
    );

    return underlyingListItems;
};
