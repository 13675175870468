import { MouseEvent } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import { ISearchUnderlying } from "src/api/client/search-autocomplete.api";
import { UnderlyingSearchItem } from "src/components/common/typeahead/nav-search-item";
import { ISearchResults } from "src/components/common/typeahead/typeahead.types";

const NAV_SEARCH_RESULTS_TEST_ID = "nav-search-results";

interface IUnderlyingSearchResultsProps extends ISearchResults {
    resultsTitle?: string;
    notFoundMessage: string;
    notFoundMessageBoxProps?: BoxProps;
    onClick?: (event: MouseEvent, underlying: ISearchUnderlying) => void;
}

export const UnderlyingSearchResults = ({
    results,
    selectedIndex,
    query,
    resultsTitle,
    notFoundMessage,
    notFoundMessageBoxProps = {},
    onClick,
}: IUnderlyingSearchResultsProps) => {
    const { underlyings } = results ?? {};

    return (
        <Box
            borderWidth={[0, 0, "1px"]}
            borderRadius="8px"
            bg="white"
            position="absolute"
            width="100%"
            overflow="hidden"
            borderColor="gray.400"
            mt={2}
            py={2}
            zIndex={["initial", "initial", "overlay"]}
            data-test-id={NAV_SEARCH_RESULTS_TEST_ID}
        >
            {resultsTitle ? (
                <Text px={[1, 3]} pb={1} fontSize="lg" fontWeight="bold" lineHeight="tall">
                    {resultsTitle}
                </Text>
            ) : undefined}

            {underlyings && underlyings.length > 0 ? (
                underlyings.map((underlying, index) => {
                    const { resourceIdentifier, shortDescription, securityType } = underlying;
                    return (
                        <UnderlyingSearchItem
                            key={resourceIdentifier}
                            query={query}
                            isSelected={index === selectedIndex}
                            resourceIdentifier={resourceIdentifier}
                            shortDescription={shortDescription}
                            securityType={securityType}
                            placement="area"
                            showBadge={false}
                            onClick={event => onClick?.(event, underlying)}
                        />
                    );
                })
            ) : (
                <Text px={[1, 3]} pb={1} lineHeight="tall" {...notFoundMessageBoxProps}>
                    {notFoundMessage}
                </Text>
            )}
        </Box>
    );
};
