import { Text } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";
import { IButtonCtaProps } from "src/types/cta";

export const ButtonCta = ({
    url,
    name,
    target,
    variant,
    colorScheme,
    w = ["100%", "auto"],
    ...props
}: IButtonCtaProps & Omit<ButtonProps, "type" | "colorScheme" | "variant" | "name">) => {
    return (
        <Button as="a" href={url} target={target} colorScheme={colorScheme ?? "green"} variant={variant ?? "solid"} w={w} {...props}>
            <Text pointerEvents="none" as="span" paddingY="1px" overflow="hidden" textOverflow="ellipsis">
                {name ?? undefined}
            </Text>
        </Button>
    );
};
