import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ArrowLink } from "src/components/base/arrow-link";
import { PopoverButtonIconInfo, WfPopover } from "src/components/base/wf-popover";
import { UnderlyingFollowButton } from "src/components/common/buttons/underlying-follow-button/underlying-follow-button";
import { getGlobals } from "src/consts/globals";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";

interface IUnderlyingsListItemContentProps {
    isin: string;
    price: number | null;
    popularityChange: number | null;
    underlyingDetailsUrl: string;
    daysCount: number;
    canBeFollowed: boolean;
}

const UnderlyingListItemDetails = ({
    isin,
    price,
    popularityChange,
    underlyingDetailsUrl,
    daysCount,
    canBeFollowed,
}: IUnderlyingsListItemContentProps) => {
    const { t } = useTranslation(["underlying-overview", "common"]);
    const numberFormatter = useNumberFormatter();
    const formattedPopularityChange =
        popularityChange !== null && popularityChange !== 0 ? numberFormatter.formatNumberWithSign(popularityChange) : "-";
    const formattedPrice = price !== null ? numberFormatter.formatNumber(price, 2) : "-";

    const getPopularityChangeColor = (change: number | null) => {
        if (change === null || change === 0) {
            return "gray.800";
        }

        return change > 0 ? "green.600" : "red.600";
    };

    return (
        <Box pl={[0, 6]} pr={4} fontSize={["sm", "md"]}>
            <Flex justifyContent="space-between">
                <Text>{t("price-in-currency", { 0: getGlobals().currency, ns: "common" })}</Text>
                <Text>{formattedPrice}</Text>
            </Flex>
            <Flex mb={3} mt={2} justifyContent="space-between">
                <Flex alignItems="center">
                    <Text>{t("popularity-change")}</Text>
                    <WfPopover content={t("popularity-change-tooltip", { 0: daysCount })}>
                        <PopoverButtonIconInfo ariaLabel={t("popularity-change-tooltip", { 0: daysCount })} ml={1} />
                    </WfPopover>
                </Flex>
                <Text color={getPopularityChangeColor(popularityChange)}>{formattedPopularityChange}</Text>
            </Flex>
            <Stack
                direction={["column", "row", "column", "row"]}
                justifyContent="space-between"
                alignItems={["flex-start", "center", "flex-start", "center"]}
                spacing={[2, 1, 2, 1]}
            >
                {canBeFollowed && <UnderlyingFollowButton underlyingIsin={isin} />}
                <ArrowLink href={underlyingDetailsUrl}>{t("all-stock-details")}</ArrowLink>
            </Stack>
        </Box>
    );
};

export { UnderlyingListItemDetails };
