import { Stack } from "@chakra-ui/react";
import { IMultiCtaProps } from "src/components/common/cta/cta.types";
import { IButtonCtaProps, ICta, ILinkCtaProps } from "src/types/cta";
import { ButtonCta } from "./button-cta";
import { LinkCta } from "./link-cta";

export const SingleCta = ({ type, w, ...props }: ICta) => {
    if (type === "link") {
        return <LinkCta {...(props as ILinkCtaProps)} />;
    } else {
        return <ButtonCta w={w} {...(props as IButtonCtaProps)} />;
    }
};

export const MultiCta = ({ cta1, cta2, isFullWidthCta }: IMultiCtaProps) => {
    if (!cta1 && !cta2) {
        return null;
    }

    return (
        <Stack align={isFullWidthCta ? "flex-start" : ["flex-start", "center"]} direction={isFullWidthCta ? "column" : ["column", "row"]} spacing={2}>
            {cta1 && <SingleCta w={isFullWidthCta ? "100%" : ["100%", "auto"]} {...cta1} />}
            {cta2 && <SingleCta w={isFullWidthCta ? "100%" : ["100%", "auto"]} {...cta2} />}
        </Stack>
    );
};
