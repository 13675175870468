import { useCallback } from "react";
import { useTranslation } from "next-i18next";
import { Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { mapBreakpointValuesToDisplayProp } from "src/components/common/buttons/underlying-follow-button/map-breakpoint-values-to-display-prop";
import { IconFilledStar, IconOutlineStar } from "src/components/icons";
import { useUnderlyingFollowing } from "src/hooks/following/use-underlying-following";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useUserStore } from "src/stores/user-store";

interface IUnderlyingFollowButtonProps {
    underlyingIsin: string;
    iconOnlyBreakpoints?: ReadonlyArray<boolean>;
}

export const UnderlyingFollowButton = ({ underlyingIsin, iconOnlyBreakpoints }: IUnderlyingFollowButtonProps) => {
    const { t } = useTranslation("common");
    const { isLoggedIn } = useUserStore();
    const { openLoginModal } = useLinkBuilder();
    const { isFollowed, toggleIsFollowed } = useUnderlyingFollowing(underlyingIsin);

    const Icon = isFollowed ? IconFilledStar : IconOutlineStar;
    const displayIcon = mapBreakpointValuesToDisplayProp(iconOnlyBreakpoints, true);
    const displayButton = mapBreakpointValuesToDisplayProp(iconOnlyBreakpoints, false);

    const handleFollowButtonClick = useCallback(() => {
        if (!isLoggedIn) {
            return openLoginModal();
        }

        toggleIsFollowed();
    }, [toggleIsFollowed, openLoginModal, isLoggedIn]);

    return (
        <>
            <Icon display={displayIcon} boxSize={3} cursor="pointer" onClick={handleFollowButtonClick} />
            <Span display={displayButton}>
                <Button variant="outline" size="sm" leftIcon={<Icon boxSize={3} />} onClick={handleFollowButtonClick}>
                    {t(isFollowed ? "unfollow" : "follow")}
                </Button>
            </Span>
        </>
    );
};
