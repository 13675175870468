import { Flex, Text } from "@chakra-ui/react";
import { PerformanceValue } from "src/components/common/value/performance-value";
import { UnderlyingLogo } from "src/components/pages/underlying-overview/underlying-logo";

interface IUnderlyingsListItemHeading {
    title: string;
    performance: number | null;
    isin: string;
}

const UnderlyingListItemHeading = ({ title, performance, isin }: IUnderlyingsListItemHeading) => (
    <Flex justifyContent="space-between" alignItems="center" width="100%" fontSize={["sm", "md"]}>
        <Flex alignItems="center" pt="4px" pb="4px">
            <UnderlyingLogo isin={isin} display={["none", "block"]} mr={2} />
            <Text fontWeight="normal">{title}</Text>
        </Flex>
        <PerformanceValue fontWeight="normal" value={performance} />
    </Flex>
);

export { UnderlyingListItemHeading };
