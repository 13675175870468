import { useCallback, useEffect, useMemo, useState } from "react";
import { Flex, Grid, Box, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfHeading } from "src/components/base/wf-heading";
import { WfSelect } from "src/components/base/wf-select";
import { EmptyState } from "src/components/common/empty-state";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { getAllItemsFromUnderlyingListModule } from "src/components/modules/underlying-lists-module/get-all-items-from-underlying-list-module";
import { EnumUnderlyingListColorScheme, UnderlyingList } from "src/components/modules/underlying-lists-module/list/underlying-list";
import {
    UNDERLYING_LIST_TEST_ID,
    UNDERLYING_LIST_DESCRIPTION_TEST_ID,
    UNDERLYING_LIST_HEADING_TEST_ID,
    UNDERLYING_LIST_SECTION_DESCRIPTION_TEST_ID,
    UNDERLYING_LIST_SECTION_HEADING_TEST_ID,
    UNDERLYING_LIST_SWITCH_BUTTON_TEST_ID,
} from "src/components/modules/underlying-lists-module/underlying-list-module.test-ids";
import { UnderlyingListSwitchButton } from "src/components/modules/underlying-lists-module/underlying-list-switch-button/underlying-list-switch-button";
import { IUnderlyingLists } from "src/components/modules/underlying-lists-module/underlying-lists-module.types";
import { HideContent } from "src/components/pages/detail/shared/hide-content";
import { EnumUnderlyingListIndex } from "src/components/pages/underlying-overview/constants";
import { usePopulateUnderlyingFollowStore } from "src/hooks/following/use-populate-underlying-follow-store";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { useUserStore } from "src/stores/user-store";

export interface IUnderlyingListModuleProps {
    title: string;
    description: string;
    underlyingLists: {
        underlyingListForSevenDays: IUnderlyingLists | null;
        underlyingListForThirtyDays: IUnderlyingLists | null;
    } | null;
    isOverlayEnabled: boolean;
    overlayTitle: string;
    overlayDescription: string;
}

const DEFAULT_LIST_ITEMS_ACCORDION_STATE = [-1, -1, -1, -1];

export const DEFAULT_DAYS_COUNT = 7;

export const UnderlyingListModule = ({
    title,
    description,
    underlyingLists,
    isOverlayEnabled,
    overlayTitle,
    overlayDescription,
}: IUnderlyingListModuleProps) => {
    const { t } = useTranslation(["underlying-overview", "common"]);
    const [listItemsAccordionState, setListItemsAccordionState] = useState(DEFAULT_LIST_ITEMS_ACCORDION_STATE);
    const [mobileVisibleListIndex, setMobileVisibleListIndex] = useState(0);
    const [daysCount, setDaysCount] = useState(DEFAULT_DAYS_COUNT);
    const isMobileOrTablet = useBreakpointValue([true, true, false]);
    const { isLoggedIn } = useUserStore();
    const { setReturnUrl } = useAuthModalStore();
    const shouldShowOverlay = isOverlayEnabled && !isLoggedIn;

    useEffect(() => {
        setReturnUrl(window.location.pathname);
    }, [setReturnUrl]);

    const shouldListBeVisible = useCallback(
        (listIndex: EnumUnderlyingListIndex) => {
            if (isMobileOrTablet) {
                return mobileVisibleListIndex === listIndex;
            }
            return true;
        },
        [isMobileOrTablet, mobileVisibleListIndex]
    );

    const onListItemAccordionExpand = useCallback(
        (listIndex: EnumUnderlyingListIndex, itemIndex: number) => {
            const newState = [...DEFAULT_LIST_ITEMS_ACCORDION_STATE];
            newState[listIndex] = itemIndex;
            setListItemsAccordionState(newState);
        },
        [setListItemsAccordionState]
    );

    const selectedLists = useMemo(
        () => underlyingLists?.[daysCount === DEFAULT_DAYS_COUNT ? "underlyingListForSevenDays" : "underlyingListForThirtyDays"],
        [underlyingLists, daysCount]
    );

    const allUnderlyingListItems = useMemo(() => getAllItemsFromUnderlyingListModule(underlyingLists), [underlyingLists]);
    usePopulateUnderlyingFollowStore(allUnderlyingListItems);

    return (
        <Container>
            <WfHeading as="h2" fontSize={["3xl", "3xl", "4xl"]} mb={[4, 3]} data-test-id={UNDERLYING_LIST_SECTION_HEADING_TEST_ID}>
                {title}
            </WfHeading>
            <Flex direction={["column", "row"]} mb={[2, 4]} gap={2}>
                <SimpleRichText flexGrow={1} text={description} fontSize={["sm", "md"]} data-test-id={UNDERLYING_LIST_SECTION_DESCRIPTION_TEST_ID} />
                <WfSelect
                    aria-label={t("look-back-days-label")}
                    flexGrow={0}
                    flexShrink={0}
                    w={["100%", "200px"]}
                    size="sm"
                    value={daysCount}
                    onChange={element => setDaysCount(parseInt(element.target.value))}
                >
                    <option value={7}>{t("look-back-days", { 0: 7 })}</option>
                    <option value={30}>{t("look-back-days", { 0: 30 })}</option>
                </WfSelect>
            </Flex>
            {selectedLists ? (
                <Box position="relative">
                    <Grid display={["grid", "grid", "none"]} templateColumns="repeat(2, 1fr)" rowGap={[2, 4]} columnGap={[2, 5]}>
                        <UnderlyingListSwitchButton
                            isActive={mobileVisibleListIndex === EnumUnderlyingListIndex.TakingProfits}
                            label={t("taking-profits")}
                            colorScheme={EnumUnderlyingListColorScheme.Teal}
                            listItems={selectedLists?.takingProfits}
                            onClick={() => setMobileVisibleListIndex(EnumUnderlyingListIndex.TakingProfits)}
                            data-test-id={`${UNDERLYING_LIST_SWITCH_BUTTON_TEST_ID}-${EnumUnderlyingListIndex.TakingProfits}`}
                        />
                        <UnderlyingListSwitchButton
                            isActive={mobileVisibleListIndex === EnumUnderlyingListIndex.FearOfMissingOut}
                            label={t("fear-of-missing-out-short")}
                            colorScheme={EnumUnderlyingListColorScheme.Green}
                            listItems={selectedLists?.fearOfMissingOut}
                            onClick={() => setMobileVisibleListIndex(EnumUnderlyingListIndex.FearOfMissingOut)}
                            data-test-id={`${UNDERLYING_LIST_SWITCH_BUTTON_TEST_ID}-${EnumUnderlyingListIndex.FearOfMissingOut}`}
                        />
                        <UnderlyingListSwitchButton
                            isActive={mobileVisibleListIndex === EnumUnderlyingListIndex.JumpingTheShip}
                            label={t("jumping-the-ship")}
                            colorScheme={EnumUnderlyingListColorScheme.Sky}
                            listItems={selectedLists?.jumpingTheShip}
                            onClick={() => setMobileVisibleListIndex(EnumUnderlyingListIndex.JumpingTheShip)}
                            data-test-id={`${UNDERLYING_LIST_SWITCH_BUTTON_TEST_ID}-${EnumUnderlyingListIndex.JumpingTheShip}`}
                        />
                        <UnderlyingListSwitchButton
                            isActive={mobileVisibleListIndex === EnumUnderlyingListIndex.BuyingTheDip}
                            label={t("buying-the-dip")}
                            colorScheme={EnumUnderlyingListColorScheme.Blue}
                            listItems={selectedLists?.buyingTheDip}
                            onClick={() => setMobileVisibleListIndex(EnumUnderlyingListIndex.BuyingTheDip)}
                            data-test-id={`${UNDERLYING_LIST_SWITCH_BUTTON_TEST_ID}-${EnumUnderlyingListIndex.BuyingTheDip}`}
                        />
                    </Grid>
                    <Grid templateColumns={["none", "none", "repeat(2, 1fr)"]} rowGap={8} columnGap={10}>
                        <UnderlyingList
                            colorScheme={EnumUnderlyingListColorScheme.Teal}
                            title={t("taking-profits")}
                            description={t("trading-motive-description", {
                                0: t("taking-profits"),
                                1: t("to-sell"),
                                2: t("rising"),
                            })}
                            items={selectedLists?.takingProfits}
                            onChange={(index: number) => onListItemAccordionExpand(EnumUnderlyingListIndex.TakingProfits, index)}
                            expandedItemIndex={listItemsAccordionState[EnumUnderlyingListIndex.TakingProfits]}
                            isVisible={shouldListBeVisible(EnumUnderlyingListIndex.TakingProfits)}
                            dataTestId={`${UNDERLYING_LIST_TEST_ID}-${EnumUnderlyingListIndex.TakingProfits}`}
                            headingTestId={`${UNDERLYING_LIST_HEADING_TEST_ID}-${EnumUnderlyingListIndex.TakingProfits}`}
                            descriptionTestId={`${UNDERLYING_LIST_DESCRIPTION_TEST_ID}-${EnumUnderlyingListIndex.TakingProfits}`}
                            daysCount={daysCount}
                            showOverlay={shouldShowOverlay}
                        />
                        <UnderlyingList
                            colorScheme={EnumUnderlyingListColorScheme.Green}
                            title={t("fear-of-missing-out")}
                            description={t("trading-motive-description", {
                                0: t("fear-of-missing-out"),
                                1: t("to-buy"),
                                2: t("rising"),
                            })}
                            items={selectedLists?.fearOfMissingOut}
                            onChange={(index: number) => onListItemAccordionExpand(EnumUnderlyingListIndex.FearOfMissingOut, index)}
                            expandedItemIndex={listItemsAccordionState[EnumUnderlyingListIndex.FearOfMissingOut]}
                            isVisible={shouldListBeVisible(EnumUnderlyingListIndex.FearOfMissingOut)}
                            dataTestId={`${UNDERLYING_LIST_TEST_ID}-${EnumUnderlyingListIndex.FearOfMissingOut}`}
                            headingTestId={`${UNDERLYING_LIST_HEADING_TEST_ID}-${EnumUnderlyingListIndex.FearOfMissingOut}`}
                            descriptionTestId={`${UNDERLYING_LIST_DESCRIPTION_TEST_ID}-${EnumUnderlyingListIndex.FearOfMissingOut}`}
                            daysCount={daysCount}
                            showOverlay={shouldShowOverlay}
                        />
                        <UnderlyingList
                            colorScheme={EnumUnderlyingListColorScheme.Sky}
                            title={t("jumping-the-ship")}
                            description={t("trading-motive-description", {
                                0: t("jumping-the-ship"),
                                1: t("to-sell"),
                                2: t("falling"),
                            })}
                            items={selectedLists?.jumpingTheShip}
                            onChange={(index: number) => onListItemAccordionExpand(EnumUnderlyingListIndex.JumpingTheShip, index)}
                            expandedItemIndex={listItemsAccordionState[EnumUnderlyingListIndex.JumpingTheShip]}
                            isVisible={shouldListBeVisible(EnumUnderlyingListIndex.JumpingTheShip)}
                            dataTestId={`${UNDERLYING_LIST_TEST_ID}-${EnumUnderlyingListIndex.JumpingTheShip}`}
                            headingTestId={`${UNDERLYING_LIST_HEADING_TEST_ID}-${EnumUnderlyingListIndex.JumpingTheShip}`}
                            descriptionTestId={`${UNDERLYING_LIST_DESCRIPTION_TEST_ID}-${EnumUnderlyingListIndex.JumpingTheShip}`}
                            daysCount={daysCount}
                            showOverlay={shouldShowOverlay}
                        />
                        <UnderlyingList
                            colorScheme={EnumUnderlyingListColorScheme.Blue}
                            title={t("buying-the-dip")}
                            description={t("trading-motive-description", {
                                0: t("buying-the-dip"),
                                1: t("to-buy"),
                                2: t("falling"),
                            })}
                            items={selectedLists?.buyingTheDip}
                            onChange={(index: number) => onListItemAccordionExpand(EnumUnderlyingListIndex.BuyingTheDip, index)}
                            expandedItemIndex={listItemsAccordionState[EnumUnderlyingListIndex.BuyingTheDip]}
                            isVisible={shouldListBeVisible(EnumUnderlyingListIndex.BuyingTheDip)}
                            dataTestId={`${UNDERLYING_LIST_TEST_ID}-${EnumUnderlyingListIndex.BuyingTheDip}`}
                            headingTestId={`${UNDERLYING_LIST_HEADING_TEST_ID}-${EnumUnderlyingListIndex.BuyingTheDip}`}
                            descriptionTestId={`${UNDERLYING_LIST_DESCRIPTION_TEST_ID}-${EnumUnderlyingListIndex.BuyingTheDip}`}
                            daysCount={daysCount}
                            showOverlay={shouldShowOverlay}
                        />
                    </Grid>
                    {shouldShowOverlay && (
                        <Box
                            position={["relative", "relative", "absolute"]}
                            marginTop={["-180px", "-240px", 0]}
                            top={[0, 0, "200px"]}
                            left={[0, 0, "50%"]}
                            width={["100%", "100%", 0]}
                        >
                            <HideContent height="auto" headline={overlayTitle} description={overlayDescription} mb={0} />
                        </Box>
                    )}
                </Box>
            ) : (
                <EmptyState
                    title={t("generic-error-heading", { ns: "common" })}
                    description={t("generic-error-message", { ns: "common" })}
                    boxShadow={undefined}
                />
            )}
        </Container>
    );
};
