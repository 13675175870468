import { HStack, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";

interface IVotingNavigationButtonsProps {
    canGoBack: boolean;
    canGoForward: boolean;
    onPreviousButtonClick: () => void;
    onNextButtonClick: () => void;
}

export const VotingNavigationButtons = ({ canGoBack, canGoForward, onPreviousButtonClick, onNextButtonClick }: IVotingNavigationButtonsProps) => {
    const { t } = useTranslation("voting-module");
    const isMobile = useBreakpointValue([true, false]);

    return canGoBack || canGoForward ? (
        <HStack justify="space-between" pt={[3, 2]} width="100%">
            <Button isDisabled={!canGoBack} onClick={onPreviousButtonClick}>
                {t("previous-voting-part-1")} {!isMobile && t("voting")}
            </Button>
            <Button isDisabled={!canGoForward} onClick={onNextButtonClick}>
                {t("next-voting-part-1")} {!isMobile && t("voting")}
            </Button>
        </HStack>
    ) : null;
};
