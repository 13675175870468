import { useEffect } from "react";
import { TIsinWithFollowData, TUnderlyingFollowStoreData, useUnderlyingFollowStore } from "src/stores/uderlying-follow-store";

export const usePopulateUnderlyingFollowStore = <T extends TIsinWithFollowData>(underlyings?: ReadonlyArray<T>) => {
    const { setUnderlyingsFollowData } = useUnderlyingFollowStore();

    useEffect(() => {
        const underlyingsFollowData: TUnderlyingFollowStoreData = {};

        underlyings?.forEach(({ isin, isFollowed, canBeFollowed }) => {
            underlyingsFollowData[isin] = {
                isFollowed,
                canBeFollowed,
            };
        });

        setUnderlyingsFollowData(underlyingsFollowData);
    }, [setUnderlyingsFollowData, underlyings]);
};
