enum EnumUnderlyingListColorScheme {
    Sky = "sky",
    Teal = "teal",
    Green = "green",
    Blue = "blue",
}

enum EnumUnderlyingListIndex {
    TakingProfits,
    FearOfMissingOut,
    JumpingTheShip,
    BuyingTheDip,
}

export { EnumUnderlyingListColorScheme, EnumUnderlyingListIndex };
