export const mapBreakpointValuesToDisplayProp = (breakpoints: ReadonlyArray<boolean> | undefined, displayWhenTrue: boolean) => {
    if (!breakpoints?.length) {
        return displayWhenTrue ? "none" : "initial";
    }

    return breakpoints.map(value => {
        if (displayWhenTrue) {
            return value ? "initial" : "none";
        } else {
            return value ? "none" : "initial";
        }
    });
};
