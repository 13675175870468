import { Text } from "@chakra-ui/react";
import { ButtonCta } from "src/components/common/cta/button-cta";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { ContainerSmall } from "src/components/layout/container";
import { ICtaModuleProps } from "./cta-module.types";

export const CtaModule = ({ title, description, cta }: Omit<ICtaModuleProps, "colorScheme">) => (
    <ContainerSmall>
        <ModulePrimaryHeadline mb={3}>{title}</ModulePrimaryHeadline>
        {description && (
            <Text mb={[3, 3, 4]} fontSize={["xl", "xl", "2xl"]} lineHeight={["tall", "tall", "base"]}>
                {description}
            </Text>
        )}
        <ButtonCta {...cta} colorScheme="gray" w={["100%", "auto"]} />
    </ContainerSmall>
);
